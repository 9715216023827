import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';


const ResetPassword = () => {
  const { token } = useParams();  // Extract the token from the URL
  const navigate = useNavigate();  // Initialize useNavigate for redirection
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showLoginButton, setShowLoginButton] = useState(false);  // State to show "Go to Login" button

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      // Send the token and new password in the POST request body
      await axios.post(`https://backend.makejahunt.co.ke/api/reset-password`, {
        token,  // Send token in the request body
        newPassword: password
      });

      // On successful reset, show the message and the "Go to Login" button
      setMessage('Password reset successfully!');
      setShowLoginButton(true);  // Show the login button
    } catch (err) {
      setError(err.response?.data?.message || 'Error resetting password');
    }
  };

  // Function to navigate to the login page
  const goToLogin = () => {
    navigate('/login');
  };

  // Function to navigate to the home page
  const goToHome = () => {
    navigate('/');
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="password">New Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Reset Password</button>
      </form>
      
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Show the "Go to Login" button if the password was successfully reset */}
      {showLoginButton && (
        <button onClick={goToLogin} style={{ marginTop: '20px', padding: '10px' }}>
          Go to Login
        </button>
      )}

      {/* Always visible "Home" button */}
      <button 
        onClick={goToHome} 
        style={{ position: 'absolute', top: '10px', right: '10px', padding: '10px' }}
      >
     
      </button>
    </div>
  );
};

export default ResetPassword;
