import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

const AttendancePage = () => {
  const [confirmedPayments, setConfirmedPayments] = useState([]);
  const [selectedAttendances, setSelectedAttendances] = useState(
    JSON.parse(localStorage.getItem('attendanceStatuses')) || {}
  );

  useEffect(() => {
    const fetchConfirmedPayments = async () => {
      try {
        const response = await axios.get('https://backend.makejahunt.co.ke/api/confirmed-payments');
        setConfirmedPayments(response.data);
      } catch (error) {
        console.error('Error fetching confirmed payments:', error);
      }
    };

    fetchConfirmedPayments();

    // Setup socket connection
    const socket = io('https://backend.makejahunt.co.ke');
    socket.on('paymentConfirmed', (data) => {
      alert(`Payment confirmed for payment ID: ${data.paymentId}`);
      setConfirmedPayments(confirmedPayments.filter(payment => payment.id !== data.paymentId));
    });

    return () => {
      socket.disconnect();
    };
  }, [confirmedPayments]);

  const handleAttend = (paymentId) => {
    setSelectedAttendances((prevStatuses) => {
      const newStatuses = { ...prevStatuses, [paymentId]: 'attending' };
      localStorage.setItem('attendanceStatuses', JSON.stringify(newStatuses));
      return newStatuses;
    });
  };

  const handleAttended = async (paymentId) => {
    try {
      await axios.post(`https://backend.makejahunt.co.ke/api/attended/${paymentId}`);
      alert('Marked as Attended');
      setSelectedAttendances((prevStatuses) => {
        const newStatuses = { ...prevStatuses, [paymentId]: 'attended' };
        localStorage.setItem('attendanceStatuses', JSON.stringify(newStatuses));
        return newStatuses;
      });
    } catch (error) {
      console.error('Error marking as attended:', error);
      alert('Error marking as attended');
    }
  };

  return (
    <div className="attendance-page">
      <h1>Attendance Page</h1>
      {confirmedPayments.length === 0 ? (
        <p>No confirmed payments to attend.</p>
      ) : (
        <table className="attendance-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Location</th>
              <th>Landlord Number</th>
              <th>dLocation</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {confirmedPayments.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.name}</td>
                <td>{payment.phoneNumber}</td>
                <td>{payment.House?.location || 'N/A'}</td>
                <td>{payment.House?.landlordNumber || 'N/A'}</td>
                <td>{payment.House?.dlocation || 'N/A'}</td>
                <td>
                  {!selectedAttendances[payment.id] ? (
                    <>
                      <button onClick={() => handleAttend(payment.id)} className="attend-button">
                        Attending
                      </button>
                      <button onClick={() => handleAttended(payment.id)} className="attended-button">
                        Attended
                      </button>
                    </>
                  ) : selectedAttendances[payment.id] === 'attending' ? (
                    <span className="attending-status">Attending...</span>
                  ) : (
                    <span className="attended-status">Attended</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AttendancePage;
