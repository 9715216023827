import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Use the environment variable for the API base URL, with fallback to makejahunt for development
const apiBaseUrl ='https://backend.makejahunt.co.ke';

const HouseDetails = () => {
  const { id } = useParams();  // Extract the house ID from the URL
  const navigate = useNavigate();
  const [house, setHouse] = useState(null);  // State to hold house data
  const [message, setMessage] = useState('');  // State to hold messages (e.g., errors)
  const [currentSlide, setCurrentSlide] = useState(0);  // State for the image/video slider
  const [isWatched, setIsWatched] = useState(false);  // State for whether the house is watched

  // Fetch house details on component mount
  useEffect(() => {
    const fetchHouseDetails = async () => {
      const houseId = parseInt(id, 10);  // Convert id to number

      if (isNaN(houseId)) {
        setMessage('Invalid house ID.');
        return;
      }

      try {
        const response = await axios.get(`${apiBaseUrl}/api/houses/${houseId}`);
        setHouse(response.data);  // Store house data in state
        setIsWatched(response.data.isWatched);  // Set if house is in the watchlist
      } catch (error) {
        console.error('Error fetching house details:', error);
        if (error.response?.status === 404) {
          setMessage('House not found.');
        } else {
          setMessage('An error occurred. Please try again later.');
        }
      }
    };

    fetchHouseDetails();
  }, [id]);

  // Handle reservation for the house
  const handleBuyClick = async () => {
    if (house.unitsAvailable > 0) {
      try {
        await axios.post(`${apiBaseUrl}/api/reserve-house/${id}`);
        navigate(`/payment`, { state: { houseId: id, price: house.price } });
      } catch (error) {
        console.error('Error reserving house:', error);
        setMessage('An error occurred while reserving the house.');
      }
    } else {
      setMessage('The house is currently booked.');
    }
  };

  // Add house to the watchlist
  const handleAddToWatchlist = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('User not authenticated. Please log in.');
        return;
      }

      await axios.post(`${apiBaseUrl}/api/watchlist/${id}`, null, {
        headers: {
          'x-auth-token': token,
        },
      });
      setIsWatched(true);
      setMessage('House added to watchlist.');
    } catch (error) {
      console.error('Error adding house to watchlist:', error);
      setMessage('An error occurred while adding the house to your watchlist.');
    }
  };

  // Slide navigation for images and videos
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === (house.photos?.length || 0) + (house.video ? 1 : 0) - 1
        ? 0
        : prevSlide + 1
    );
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0
        ? (house.photos?.length || 0) + (house.video ? 1 : 0) - 1
        : prevSlide - 1
    );
  };

  // Show loading spinner while house data is being fetched
  if (!house) {
    return <div>Loading...</div>;
  }

  return (
    <div className="houseid">
      <header className="headerD">
        {/* Back Button with Arrow Icon */}
        <button className="back-button" onClick={() => navigate(-1)}>
          <ion-icon name="arrow-back-outline"></ion-icon>
        </button>
        <h2>{house.name}</h2>
      </header>

      {/* Media Slider for house images and videos */}
      <div className="media-slider">
        <div
          className="media-slider-container"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {house.photos &&
            house.photos.map((photo, index) => (
              <img
                key={index}
                src={`${apiBaseUrl}/api/${photo}`}  // Dynamic API URL for photos
                alt="House"
              />
            ))}
          {house.video && (
            <video controls>
              <source src={`${apiBaseUrl}/api/${house.video}`} />  
            </video>
          )}
        </div>
        <div className="slider-control left" onClick={handlePrevSlide}>
          &#10094;
        </div>
        <div className="slider-control right" onClick={handleNextSlide}>
          &#10095;
        </div>
      </div>

      <div className="house-content">
        <p className="houseD">
          <strong>Type:</strong> {house.type}
        </p>
        <p className="houseD">
          <strong>Price:</strong> ${house.price}
        </p>
        <p className="houseD">
          <strong>Description:</strong> {house.description}
        </p>
        <p className="houseD">
          <strong>Units Available:</strong> {house.unitsAvailable}
        </p>
        <p className="houseD">
          <strong>Size:</strong> {house.size} sq ft
        </p>
        <p className="houseD">
          <strong>Water Availability (24/7):</strong> {house.waterAvailability ? 'Yes' : 'No'}
        </p>
        <p className="houseD">
          <strong>Electricity Token:</strong> {house.electricityAvailability ? 'Yes' : 'No'}
        </p>
        <p className="houseD">
          <strong>Location:</strong> {house.location}
        </p>

        {/* Footer section with RENT and Watchlist buttons */}
        <div className="Hcard-footer">
          <div className="Hcard-footer-actions">
            {message && <p className="error-message">{message}</p>}
            <button
              className="btn"
              onClick={handleBuyClick}
              disabled={house.unitsAvailable === 0}
            >
              {house.unitsAvailable === 0 ? 'Currently Booked' : 'RENT'}
            </button>
            {house.unitsAvailable === 0 && (
              <button
                className="btn"
                onClick={handleAddToWatchlist}
                disabled={isWatched}
              >
                {isWatched ? 'Watched' : 'Add to Watchlist'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseDetails;
