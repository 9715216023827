import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Profile from './profile';
import axios from 'axios';

const Home = ({ isAuthenticated, user }) => {
  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('token'));

  // Logout function to clear token and user info
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setToken(null);
    navigate('/login');  // Redirect to login page on logout
  };

  // Set the token in axios headers if available
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [token]);

  // Handle house list navigation based on authentication
  const handleBuyClick = () => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      navigate('/HouseList');
    }
  };
return (
<div class="homebody">


<header class="Hheader" data-header>

  <div class="Hoverlay" data-overlay></div>

  <div class="Hheader-top">
    <div class="Hcontainer">

      <ul class="Hheader-top-list">

        <li>
          <p class="Hheader-top-link">
            <ion-icon name="mail-outline"></ion-icon>

            <span>makejahunt254@GMAIL.COM</span>
            </p>
        </li>

        <li>
          <p class="Hheader-top-link">
            <ion-icon name="location-outline"></ion-icon>

            <address>MACHAKOS</address>
          </p>
        </li>

      </ul>

      <div class="Hwrapper">
        <ul class="Hheader-top-social-list">

          <li>
            <a href="https://www.facebook.com/samde.brun.3?mibextid=ZbWKwL" class="Hheader-top-social-link">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
          </li>

          <li>
            <a href="https://x.com/makejahunt?t=K0FlSxWM5cMwkd6L0xJx7w&s=09" class="Hheader-top-social-link">
              <ion-icon name="logo-twitter"></ion-icon>
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/samueloti_1?igsh=MW5zMjY2OWhseHA4ZA==" class="Hheader-top-social-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>

          <li>
            <a href="https://wa.me/254799692417" class="Hheader-top-social-link">
              <ion-icon name="logo-whatsapp"></ion-icon>
            </a>
          </li>

        </ul>
        <div>
        {token ? (
                  <button class="Hheader-top-btn" onClick={logout}>LOGOUT</button>
                ) : (
                  <button class="Hheader-top-btn" onClick={() => navigate('/login')}>LOGIN</button>
                )}
    </div>
       
      </div>

    </div>
  </div>

  <div class="Hheader-bottom">
    <div class="Hcontainer">

     

      <nav class="Hnavbar" data-navbar>

        <div class="Hnavbar-top">

           
            <img class="logo" src="./images/logo.png" alt="verse logo"/>
          

          <button class="Hnav-close-btn" data-nav-close-btn aria-label="Close Menu">
            <ion-icon name="close-outline"></ion-icon>
          </button>

        </div>

       
 
      </nav>

      <div class="Hheader-bottom-actions">

     
      <li>
              <a href="#home" class="Hnavbar-link" data-nav-link>Home</a>
            </li>

            <li>
              <a href="#about" class="Hnavbar-link" data-nav-link>About</a>
            </li>

            <li>
              <a href="#service" class="Hnavbar-link" data-nav-link>Service</a>
            </li>

            <li>
              <a href="#contact" class="Hnavbar-link" data-nav-link>Contact</a>
            </li>

            <li>
              <button href="#property" class="Hnavbar-link" onClick={handleBuyClick} data-nav-link>Houses</button>
            
            </li>

            <li>
            {isAuthenticated ? (
                <>
                  {showProfile && <Profile user={user} onClose={() => setShowProfile(false)} />}
                </>
              ) : (
                <button className="Hnavbar-link" onClick={() => navigate('/login')}>Login</button>
              )}
               </li>


               {isAuthenticated && (

        <button   class="Hheader-bottom-actions-btn" aria-label="Profile" onClick={() => setShowProfile(true)} >
          <ion-icon name="person-outline"></ion-icon>

          <span>Profile</span>
        </button>
              )}
    
      </div>

    </div>
  </div>

</header>





<main>
<article>

   

  

  <section class="Hhero" id="home">
    <div class="Hcontainer">

      <div class="Hhero-content">

        <p class="Hhero-subtitle">
          <ion-icon name="home"></ion-icon>

          <span>makejahunt Agency</span>
        </p>

        <h2 class="Hh1 Hhero-title" id="housetype" >Tired of walking around looking for rentals?</h2>

        <p class="Hhero-text">
         We have made your search easier.You Just have sit back and select the kind of house you want.
         <br/> Start by clicking here to select a house
        </p>

        <button onClick={handleBuyClick}  class="Hbtn">houses</button>

        

      </div>

      <figure class="Hhero-banner">
        <img src="./images/property-2.jpg" alt="Modern house model" class="Hw-100"/>
      </figure>

    </div>
  </section>


  <section class="Habout" id="about">
    <div class="Hcontainer">

    <figure class="Habout-banner">

<img src='images/about-banner-2.jpg' alt="House interior" class="Habs-img"/>

</figure>

      <div class="Habout-content">

        <p class="Hsection-subtitle">About Us</p>

        <h2 class="Hh2 Hsection-title">The Leading Real Estate Rental Marketplace.</h2>

        <p class="Habout-text">
          A team that is dedicated in finding rentals and offering
          specialist services
        </p>

        <ul class="Habout-list">

          <li class="Habout-item">
            <div class="Habout-item-icon">
              <ion-icon name="home-outline"></ion-icon>
            </div>

            <p class="Habout-item-text">Smart Home Design</p>
          </li>

          <li class="Habout-item">
            <div class="Habout-item-icon">
              <ion-icon name="leaf-outline"></ion-icon>
            </div>

            <p class="Habout-item-text">Beautiful Scene Around</p>
          </li>

          <li class="Habout-item">
            <div class="Habout-item-icon">
              <ion-icon name="wine-outline"></ion-icon>
            </div>

            <p class="Habout-item-text">Exceptional Lifestyle</p>
          </li>

          <li class="Habout-item">
            <div class="Habout-item-icon">
              <ion-icon name="shield-checkmark-outline"></ion-icon>
            </div>

            <p class="Habout-item-text">Complete 24/7 Security</p>
          </li>

        </ul>

        <p class="Hcallout">
          look for a home while resting at Home
        </p>

        <a href="#service" class="Hbtn">Our Services</a>

      </div>

    </div>
  </section>





   
  
  

  <section class="Hservice" id="service">
    <div class="Hcontainer">

      <p class="Hsection-subtitle">Our Services</p>

      <h2 class="Hh2 Hsection-title">Our Main Focus</h2>

      <ul class="Hservice-list">

        <li>
          <div class="Hservice-card">

            <div class="Hcard-icon">
              <img class="forex-logo" src="images/service-1.png" alt="Service icon"/>
            </div>

            <h3 class="Hh3 card-title">
              <p>Buy a home</p>
            </h3>

            <p class="Hcard-text">
              We are working to make buying a home also an easy task.
            </p>

            <p href="#" class="Hcard-link">
              <span>Buy A Home</span>

              <ion-icon name="arrow-forward-outline"></ion-icon>
            </p>

          </div>
        </li>

        <li>
          <div class="Hservice-card">

            <div class="Hcard-icon">
              <img class="forex-logo" src="images/service-2.png" alt="Service icon"/>
            </div>

            <h3 class="Hh3 card-title">
              <p >Rent a home</p>
            </h3>

            <p class="Hcard-text">
              over 1 hundred+ homes for renting available on the website, we can match you with a house you will want
              to call home.
            </p>

            <p  class="Hcard-link">
              <span href="#property"  onClick={handleBuyClick} >Find A Home</span>

              <ion-icon name="arrow-forward-outline"></ion-icon>
            </p>

          </div>
        </li>

        <li>
          <div class="Hservice-card" onClick={() => navigate('/coming')}>

            <div class="Hcard-icon">
              <img class="forex-logo" src="images/service-3.png" alt="Service icon"/>
            </div>

            <h3 class="Hh3 card-title">
              <p href="#">Landlord Renting a house?</p>
            </h3>

            <p class="Hcard-text">
              Are you a landlord looking for tenants? Worry no more, looking for tenants has just become easy.
            </p>

            <p href="#" class="Hcard-link">
              <span>I offer renting</span>

              <ion-icon name="arrow-forward-outline"></ion-icon>
            </p>

          </div>
        </li>

      </ul>

    </div>
  </section>



  <section className="Hservice" id="other-services">
      <div className="Hcontainer">
        <p className="Hsection-subtitle">Other Services</p>
        <h2 className="Hh2 Hsection-title">Explore Our Other Services</h2>
        <ul className="Hservice-list">
          <li>
            <div className="Hservice-card" onClick={() => navigate('/webservices')}>
              <div className="Hcard-icon">
                <img className="forex-logo" src="images/service-5.png" alt="Web Services icon" />
              </div>
              <h3 className="Hh3 card-title">
                <p>Web Services</p>
              </h3>
              <p className="Hcard-text">Get your own website fast and easy with our specialized services.</p>
              <p className="Hcard-link">
                <span>Explore Web Services</span>
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </p>
            </div>
          </li>
      
          <li>
            <div className="Hservice-card" onClick={() => navigate('/secondHanditems')}>
              <div className="Hcard-icon">
                <img className="forex-logo" src="images/service-6.webp" alt="Second Hand Items Service icon" />
              </div>
              <h3 className="Hh3 card-title">
                <p>Second Hand Items</p>
              </h3>
              <p className="Hcard-text">Sell your items or find a wide range of second-hand items at great prices.</p>
              <p className="Hcard-link">
                <span>Explore Items</span>
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </p>
            </div>
          </li>

          <li>
            <div className="Hservice-card" onClick={() => navigate('/LogoServices')}>
              <div className="Hcard-icon">
                <img className="forex-logo" src="images/service-7.png" alt="Second Hand Items Service icon" />
              </div>
              <h3 className="Hh3 card-title">
                <p>Logo</p>
              </h3>
              <p className="Hcard-text">Get a logo either for your company, club or organisation</p>
              <p className="Hcard-link">
                <span>Explore Items</span>
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </p>
            </div>
          </li>


          <li>
            <div className="Hservice-card" onClick={() => navigate('/videoServices')}>
              <div className="Hcard-icon">
                <img className="forex-logo" src="images/service-8.jpg" alt="Second Hand Items Service icon" />
              </div>
              <h3 className="Hh3 card-title">
                <p>Video Services</p>
              </h3>
              <p className="Hcard-text">
                Get outstanding videography services for your events and occasions
               <br/>
              or even ads!!!</p>
              <p className="Hcard-link">
                <span>Explore Items</span>
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </p>
            </div>
          </li>



        </ul>
      </div>
    </section>
    
  

  <section class="Hfeatures">
    <div class="Hcontainer">

      <p class="Hsection-subtitle">Our Aminities</p>

      <h2 class="Hh2 Hsection-title">Building Aminities</h2>

      <ul class="Hfeatures-list">

        <li>
          <a href="m" class="Hfeatures-card">

            <div class="Hcard-icon">
              <ion-icon name="car-sport-outline"></ion-icon>
            </div>

            <h3 class="Hcard-title">Parking Space</h3>

            <div class="Hcard-btn">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>

          </a>
        </li>

        <li>
          <p href="#" class="Hfeatures-card">

            <div class="Hcard-icon">
              <ion-icon name="water-outline"></ion-icon>
            </div>

            <h3 class="Hcard-title">Water</h3>

            <div class="Hcard-btn">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>

          </p>
        </li>

        <li>
          <p href="#" class="Hfeatures-card">

            <div class="Hcard-icon">
              <ion-icon name="shield-checkmark-outline"></ion-icon>
            </div>

            <h3 class="Hcard-title">Security</h3>

            <div class="Hcard-btn">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>

          </p>
        </li>



        <li>
          <p href="#" class="Hfeatures-card">

            <div class="Hcard-icon">
              <ion-icon name="home-outline"></ion-icon>
            </div>

            <h3 class="Hcard-title">Electricity</h3>

            <div class="Hcard-btn">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>

          </p>
        </li>

        <li>
          <p href="#" class="Hfeatures-card">

            <div class="Hcard-icon">
              <ion-icon name="football-outline"></ion-icon>
            </div>

            <h3 class="Hcard-title">clean Environment</h3>

            <div class="Hcard-btn">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>

          </p>
        </li>

      </ul>

    </div>
  </section>







  
  
  

  <section class="Hcta">
    <div class="Hcontainer">

      <div class="Hcta-card">
        <div class="Hcard-content">
          <h2 class="Hh2 card-title">Looking for a dream home?</h2>

          <p class="Hcard-text">We can help you realize your dream of a new home</p>
        </div>

        <button class="Hbtn Hcta-btn">
          <span>Explore Properties</span>

          <ion-icon name="arrow-forward-outline"></ion-icon>
        </button>
      </div>

    </div>
  </section>

</article>
</main>









<footer class="Hfooter">

<div class="Hfooter-top">
  <div class="Hcontainer">

    <div class="Hfooter-brand">

      

      <p class="Hsection-text">
        We are a fully dedicated agency to help you find your desired rental house and making your search less tiresome.
      </p>
        <p class="Hfooter-list-title">Contacts</p>
    
      <ul class="Hcontact-list" id="contact">

        <li>
          <p  class="Hcontact-link">
            <ion-icon name="location-outline"></ion-icon>

            <address>Machakos Town</address>
          </p>
        </li>

        <li>
          <a href="tel:+0123456789" class="Hcontact-link">
            <ion-icon name="call-outline"></ion-icon>

            <span>+254-714234491</span>
          </a>
        </li>

        <li>
          <a href="mailto:makejahunt@gmail.com" class="Hcontact-link">
            <ion-icon name="mail-outline"></ion-icon>

            <span>makejahunt@gmail.com</span>
          </a>
        </li>

      </ul>

      <ul class="Hsocial-list">

        <li>
            <a href="https://wa.me/254799692417" class="Hheader-top-social-link">
              <ion-icon name="logo-whatsapp"></ion-icon>
            </a>
          </li>

        <li>
          <a href="https://x.com/makejahunt?t=K0FlSxWM5cMwkd6L0xJx7w&s=09" class="Hsocial-link">
            <ion-icon name="logo-twitter"></ion-icon>
          </a>
        </li>

        <li>
          <a href="https://www.instagram.com/samueloti_1?igsh=MW5zMjY2OWhseHA4ZA==" class="Hsocial-link">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
        </li>

       

      </ul>

    </div>

    <div class="Hfooter-link-box">

      <ul class="Hfooter-list">

        <li>
          <p class="Hfooter-list-title">Company</p>
        </li>

        <li>
          <a href="#About" class="Hfooter-link">About</a>
        </li>


        <li>
          <a href="houselist" class="Hfooter-link">All Products</a>
        </li>

        <li>
          <p class="Hfooter-link">FAQ</p>
        </li>

        <li>
          <a href="makejahunt@gmail.com" class="Hfooter-link">Contact us</a>
        </li>

      </ul>

      <ul class="Hfooter-list">

        <li>
          <p class="Hfooter-list-title">Services</p>
        </li>

      
        
        <li>
          <a href="/Terms" class="Hfooter-link">Terms & Conditions</a>
        </li>

      

      </ul>

      

    </div>

  </div>
</div>

<div class="Hfooter-bottom">
  <div class="container">

    <p class="Hcopyright">
      &copy; 2024 <a href="makejahunt@gmail.com">makejahunt</a>. All Rights Reserved
    </p>

  </div>
</div>

</footer>

</div>





 



);
};
export default Home;